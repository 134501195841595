export const isIos = () => {
  if (!process.client) return
  return /iP(hone|(o|a)d)/.test(navigator.userAgent)
}
export const isMac = () => {
  if (!process.client) return
  return /Macintosh|Mac OS/.test(navigator.userAgent)
}
export const isChrome = () => {
  if (!process.client) return
  return /Chrome/.test(navigator.userAgent)
}
export const isSafari = () => {
  if (!process.client) return
  const isSafari = /Safari/.test(navigator.userAgent)
  // Chromeにも"Safari"が含まれているので、Chromeかどうかもチェックする
  return isSafari && !isChrome()
}
export const isMobile = () => {
  if (!process.client) return
  return /iPhone|Android.+Mobile/.test(navigator.userAgent)
}
export const isPwa = () => {
  if (!process.client) return false
  return window.matchMedia('(display-mode: standalone)').matches
}
